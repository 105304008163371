import React from "react";
import "./css/Platform.css";
import { StaticImage } from "gatsby-plugin-image";
import Google from "../assets/images/google-play.svg";
import Mac from "../assets/images/logo-mac-store.svg";

function Platform() {
    return (
        <section className="platform">
            <div id="platform" className="platform__link-handling-position" />
            <StaticImage
                className="platform__img"
                src="../assets/images/iphone-mockup@2x.png"
                alt="platform img"
                placeholder="blurred"
                loading="eager"
                quality={75}
            />
            <div className="platform__buttons">
                <a
                    href="https://apps.apple.com/us/app/krn-pilates-train-workout/id1588022926"
                    className="platform__link"
                    aria-label="apple"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <Mac className="platform__button" />
                </a>
                <a
                    href="https://play.google.com/store/apps/details?id=com.balancedbody.pilates"
                    className="platform__link"
                    aria-label="android"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <Google className="platform__button" />
                </a>
            </div>
        </section>
    );
}

export default Platform;
