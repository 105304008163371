import React, { useState } from "react";
import Layout from "./Layout.js";
import Hero from "./Hero.js";
import Stock from "./Stock.js";
import Instructors from "./Instructors.js";
import VideoModal from "./VideoModal.js";

function App() {
  const [modal, setModal] = useState({
    isOpen: false,
    src: null
  });

  return (
    <Layout title="Krn-Pilates" desc="krn-pilates main page">
      <Hero openModal={() => setModal(({isOpen: true, src: "https://www.youtube.com/embed/UEPuiJ7B-Os"}))} />
      <Stock openModal={(src) => setModal({isOpen: true, src})} />
      <Instructors />
      <VideoModal 
          state={modal} 
          closeModal={() => setModal(prev => ({...prev, isOpen: false}))}
      />
    </Layout>
  );
}

export default App;
