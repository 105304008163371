import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import "./css/Loading.css"

const Loading = () => {
    return (
        <div className={"loading"}>
            <AiOutlineLoading3Quarters size={"60px"} />
        </div>
    );
};

export default Loading;
