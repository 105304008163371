// Imports
import React from "react";

// CSS
import * as FooterStyles from "./css/Footer.module.css";

// Components
import FacebookIcon from "../assets/images/footer-facebook-icon.svg";
// import TwitterIcon from "../assets/images/footer-twitter-icon.svg";
// import LinkedinIcon from "../assets/images/footer-in-icon.svg";
import InstagramIcon from "../assets/images/footer-insta-icon.svg";
// import PintrestIcon from "../assets/images/footer-pintrest-icon.svg";

// ======== Component ========
const Footer = () => {
  return (
    <footer className={`flex w100 ${FooterStyles.footer}`}>
      <div className={`content-wrapper flex ${FooterStyles.footerContainer}`}>
        {/* Social icons */}
        <ul className={`flex flex-row ${FooterStyles.socialList}`}>
          <li>
            <a
              href="https://www.facebook.com/BalancedBodyInc/"
              rel="noreferrer"
              target="_blank"
            >
              <FacebookIcon className={FooterStyles.icon} />
            </a>
          </li>
          {/* <li>
            <a
              href="https://twitter.com/balanced_body?lang=en"
              rel="noreferrer"
              target="_blank"
            >
              <TwitterIcon className={FooterStyles.icon} />
            </a>
          </li> */}
          {/* <li>
            <a
              href="https://www.linkedin.com/company/balanced-body/mycompany/"
              rel="noreferrer"
              target="_blank"
            >
              <LinkedinIcon className={FooterStyles.icon} />
            </a>
          </li> */}
          <li>
            <a
              href="https://www.instagram.com/balanced_body/?hl=en"
              rel="noreferrer"
              target="_blank"
            >
              <InstagramIcon className={FooterStyles.icon} />
            </a>
          </li>
          {/* <li>
            <a
              href="https://www.pinterest.com/balancedbody/_created/"
              rel="noreferrer"
              target="_blank"
            >
              <PintrestIcon className={FooterStyles.icon} />
            </a>
          </li> */}
        </ul>

        <p className={FooterStyles.companyInfo}>
          Balanced Body 5909 88th Street Sacramento, CA 95828 USA
          <br />
          <a href="tel:18007452837">1-800-PILATES (800-745-2837)</a> or{" "}
          <a href="tel:19163882838">916-388-2838</a>
        </p>

        {/* Nav */}
        <nav>
          <ul className={`flex flex-row ${FooterStyles.linkList}`}>
            <li>
              <a href="https://www.pilates.com/company/balanced-body-privacy-policy">
                Privacy
              </a>
            </li>
            <li>
              <a href="https://www.pilates.com/company/balanced-body-legal-notice">
                Legal Notice
              </a>
            </li>
            {/* <li>
              <a href="https://www.pilates.com/company/careers-with-us">
                Job Opportunities
              </a>
            </li> */}
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
