// Imports
import React from "react";
import PropTypes from "prop-types";

// CSS
import * as Styles from "./css/Layout.module.css";

// Components
import Header from "./Header";
import Footer from "./Footer";
import HeadTags from "./HeadTags";

// ======== Component ========
const Layout = (props) => {
  return (
    <main className={`flex flex-jc-fs ${Styles.layout}`}>
      <HeadTags title={props.title} desc={props.desc} />
      <Header />
      <div className={Styles.layoutMain}>
        {props.children}
      </div>
      <Footer />
    </main>
  );
};

// Prop validation
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

Layout.defaultProps = {
  title: "Balanced Body",
};

export default Layout;
