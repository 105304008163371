// Imports
import React from "react";
// import { myContext } from "../components/Provider";

// // CSS
// import * as Styles from "./css/Index.module.css";

// Components
import App from "../components/App.js";

// ======== Component ========
const Index = () => {
  return <App />;
};

export default Index;
