import React from "react";
import "./css/Stock.css";
import stockData from "../assets/data/stock.json";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import * as Functions from "../assets/utils/functions.js";

function Stock({ openModal }) {
    const data = useStaticQuery(graphql`
        {
            allFile(filter: { relativeDirectory: { eq: "stock" } }) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
                        }
                        name
                    }
                }
            }
        }
    `);

    return (
        <section className="stock">
            {/* Cards */}
            <ul className="stock__cards">
                {stockData.map((stock, i) => {
                    const pathToImage = getImage(Functions.findImage(data, stock.image));
                    return (
                        <li key={i} className="stock__card">
                            <GatsbyImage
                                image={pathToImage}
                                alt={stock.title || "module image"}
                                className="stock__img"
                            />
                            <div className="stock__body">
                                {stock.title && (
                                    <p className="stock__title"> {stock.title} </p>
                                )}
                                <p className="stock__text"> {stock.about} </p>
                                {stock.list && (
                                    <ul className="stock__list">
                                        {stock.list.map((item, i) => (
                                            <li key={i}>{item}</li>
                                        ))}
                                    </ul>
                                )}
                                {stock.link.video ? (
                                    <button
                                        type="button"
                                        className="stock__link button"
                                        aria-label={stock.link.name}
                                        onClick={() => openModal(stock.link.video)}
                                    >
                                        <span>{stock.link.name}</span>
                                    </button>
                                ) : (
                                    <a
                                        href={`${stock.link.instructors ? "#instructors" : "#platform"}`}
                                        className="stock__link button"
                                        aria-label="learn more"
                                    >
                                        <span>{stock.link.name}</span>
                                    </a>
                                )}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
}

export default Stock;
