import React from "react";
import * as Styles from "./css/Hero.module.css";
import { StaticImage } from "gatsby-plugin-image";
import Platform from "./Platform.js";

function Hero(props) {
  return (
    <section className={Styles.hero}>
      <StaticImage
        className={Styles.hero__img}
        src="../assets/images/main-banner@2x.jpg"
        alt="platform img"
        placeholder="blurred"
        loading="eager"
        objectFit="cover"
        quality={100}
      />
      <h1 className={Styles.hero__title}>KRN Pilates</h1>
      <button
        type="button"
        className={Styles.hero__videoLink}
        onClick={props.openModal}
      >
        <p className={Styles.hero__videoText}>more from Kathi</p>
        <i type="button" className={Styles.hero__videoIcon} />
      </button>
      <Platform />
    </section>
  );
}

export default Hero;
