import React, { useState, useRef, useEffect, useCallback } from "react";
import "./css/Instructors.css";
import instructorsData from "../assets/data/instructor.json";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import * as Functions from "../assets/utils/functions.js";

import IG from "../assets/images/icon-ig-gray.svg";
import Email from "../assets/images/icon-email-gray.svg";
import Website from "../assets/images/icon-website-gray.svg";

function Instructors() {
    const data = useStaticQuery(graphql`
        {
            allFile(filter: { relativeDirectory: { eq: "instructor" } }) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                quality: 80
                                layout: CONSTRAINED
                            )
                        }
                        name
                    }
                }
            }
        }
    `);

    const [isOpen, setPopup] = useState([]);
    const [disableButton, setDisable] = useState("");
    const cardsRef = useRef();
    const cardRef = useRef([]);
    const refFunction = useCallback((element) => cardRef.current.push(element), []);

    useEffect(() => {
        let timeout;
        const last = isOpen.at(-1);
        if (isOpen.length && !Object.keys(last).length) {
            timeout = setTimeout(() => setPopup([]), 400);
        }

        return () => clearTimeout(timeout);
    }, [isOpen]);

    useEffect(() => {
        const cardArr = cardRef.current;
        const options = {
            root: cardsRef.current,
            rootMargin: "8px 0px 0px 0px",
            threshold: 0.99,
        };
        function callBack(enteries) {
            const [first, last] = enteries;

            if (first && first.isIntersecting) {
                return first.target.nextSibling ? setDisable("prev") : setDisable("next");
            }
            if (last && last.isIntersecting) return setDisable("next");
            return setDisable("");
        }
        const observer = new IntersectionObserver(callBack, options);
        cardArr.forEach((referenceElement) => observer.observe(referenceElement));

        return () => {
            cardArr.forEach((referenceElement) => observer.unobserve(referenceElement));
        };
    }, [cardsRef, cardRef]);

    function handleNextClick() {
        cardsRef.current.scrollBy({
            left: cardsRef.current.offsetWidth - (cardsRef.current.offsetWidth % 299),
            behavior: "smooth",
        });
    }

    function handlePrevClick() {
        cardsRef.current.scrollBy({
            left: -cardsRef.current.offsetWidth + (cardsRef.current.offsetWidth % 299),
            behavior: "smooth",
        });
    }

    return (
        <div className="instructors" id="instructors">
            <h2 className="instructors__heading">Guest Instructors</h2>

            {/* Instructors cards */}
            <div ref={cardsRef} className="instructors__cards-container">
                <ul className="instructors__cards">
                    {instructorsData.map((instructor, i) => {
                        const pathToImage = getImage(
                            Functions.findImage(data, instructor.img)
                        );

                        return (
                            <li
                                ref={
                                    i === 0 || i === instructorsData.length - 1
                                        ? refFunction
                                        : null
                                }
                                key={i}
                                className="instructors__card"
                                onClickCapture={
                                    isOpen.at(-1) !== instructor &&
                                    isOpen.at(-2) !== instructor
                                        ? () => setPopup((prev) => [...prev, instructor])
                                        : null
                                }
                            >
                                <GatsbyImage
                                    image={pathToImage}
                                    alt={instructor.name}
                                    className="instructors__img"
                                    loading="eager"
                                />
                                <p className="instructors__name">{instructor.name}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>

            {/* Next & Prev Buttons */}
            <div className="instructors__buttons">
                <button
                    className="instructors__button instructors__button_prev"
                    type="button"
                    aria-label="previous"
                    onClick={handlePrevClick}
                    disabled={disableButton === "prev"}
                />
                <button
                    className="instructors__button instructors__button_next"
                    type="button"
                    aria-label="next"
                    onClick={handleNextClick}
                    disabled={disableButton === "next"}
                />
            </div>

            {/* Popup instructor info */}
            {isOpen.map((instructor, i) => {
                const { name, about, coOwner, contact } = instructor;
                return (
                    name && (
                        <div
                            key={i}
                            className={`instructors__popup-container`}
                            tabIndex={i}
                            role="presentation"
                            onBlur={() =>
                                setPopup((prev) =>
                                    isOpen.length === i + 1 ? [...prev, {}] : prev
                                )
                            }
                            onKeyUp={(e) =>
                                e.key === "Escape" && setPopup((prev) => [...prev, {}])
                            }
                        >
                            <div
                                className={`instructors__popup ${
                                    i === isOpen.length - 1 ? "slidein" : "slideout"
                                }`}
                                onAnimationEnd={i+1 === isOpen.length ? (e) => e.target.parentElement.focus() : null}
                            >
                                <h3 className="instructors__popup-name">{name}</h3>
                                <p className="instructors__popup-about">{about}</p>
                                <div className="instructors__popup-wrapper">
                                    <p className="instructors__popup-heading">CONTACT</p>
                                    <div className="instructors__popup-contact-wrapper">
                                        {Object.entries(contact)
                                            .filter(([k, v]) => v)
                                            .map(([key, value], i) => {
                                                return (
                                                    <a
                                                        key={i}
                                                        href={`${
                                                            key === "email"
                                                                ? "mailto:" + value
                                                                : key === "phone"
                                                                ? "tel:" + value
                                                                : value
                                                        }`}
                                                        rel="noreferrer noopener"
                                                        aria-label={key}
                                                        title={key}
                                                        target={
                                                            key === "website" ||
                                                            key === "instagram"
                                                                ? "_blank"
                                                                : ""
                                                        }
                                                    >
                                                        {key === "email" ? (
                                                            <Email />
                                                        ) : key === "instagram" ? (
                                                            <IG />
                                                        ) : (
                                                            <Website />
                                                        )}
                                                    </a>
                                                );
                                            })}
                                    </div>
                                </div>
                                <div className="instructors__popup-wrapper">
                                    <p className="instructors__popup-heading">CO-OWNER</p>
                                    <div>
                                        <p className="instructors__popup-text">
                                            {coOwner}
                                        </p>
                                        {/* <p className="instructors__popup-text">
                                            Studio City, CA USA
                                        </p> */}
                                    </div>
                                </div>
                                {/* <div className="instructors__popup-wrapper">
                                    <p className="instructors__popup-heading">
                                        CERTIFICATIONS
                                    </p>
                                    <p className="instructors__popup-text">
                                        ACE, AFAA, NASM, PMA, Master Instructor Neurac,
                                        Redcord, Certified Craniosacral Therapist,
                                        Visceral Thearpist
                                    </p>
                                </div> */}
                                {/* <div className="instructors__popup-wrapper">
                                    <p className="instructors__popup-heading">
                                        MEMBERSHIPS
                                    </p>
                                    <p className="instructors__popup-text">
                                        APTA (American Physical Therapy Association),
                                        IADMS (International Association of Dance
                                        Medicine)
                                    </p>
                                </div> */}
                                <button
                                    className="instructors__button instructors__button_close"
                                    type="button"
                                    aria-label="close"
                                    onClick={() => setPopup((prev) => [...prev, {}])}
                                />
                            </div>
                        </div>
                    )
                );
            })}
        </div>
    );
}

export default Instructors;
