import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";

import Loading from "./Loading";

import "./css/VideoModal.css";

function VideoModal({ closeModal, state }) {
    const [isLoading, setLoading] = useState(true);

    function handleClose(e) {
        if (e.target === e.currentTarget) {
            closeModal();
            setLoading(true);
        }
    }

    return (
        <CSSTransition unmountOnExit in={state.isOpen} classNames="modal" timeout={430}>
            <div
                className="modal"
                onClick={handleClose}
                role="menu"
                tabIndex={0}
                onMouseEnter={(e) => e.target.focus()}
                onKeyUp={(e) => e.key === "Escape" && closeModal()}
            >
                <div className="modal__wrapper">
                    {isLoading && <Loading />}
                    <iframe
                        src={state.src}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        loading="eager"
                        onLoad={() => setLoading(false)}
                    ></iframe>
                </div>
            </div>
        </CSSTransition>
    );
}

export default VideoModal;
